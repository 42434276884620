// import { chineseReg } from '@/utils/validation'
// const chineseValidation = {
//   required: true, pattern: /^[^\u4e00-\u9fa5]+$/, message: 'Input English only', trigger: 'blur'
// }
const baseRequirement = { required: true, message: 'Please fill in the correct value', trigger: 'change' }
const baseSelect = {
  required: true, message: 'Please select at least one option', trigger: 'change'
}
export default {
  entity_sof_list: [
    baseSelect
  ],
  source_of_funds_text: [baseRequirement],
  entity_pof_other_text: [baseRequirement],
  entity_purpose_of_transaction_text: [
    baseRequirement
  ],
  entity_purpose_of_transaction_other_text: [baseRequirement],
  entity_transaction_country_list: [
    baseRequirement
  ],
  entity_num_of_anual_transactions: [baseRequirement],
  entity_frequency_of_transaction: [baseRequirement],
  entity_total_volumn_of_trasaction_per_year: [baseRequirement],
  entity_average_volume_of_per_transaction: [baseRequirement]
}
